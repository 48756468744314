import { useMemo } from 'react';

import { useApiClient } from '../../GraphQL/api/client';
import { useGetDocumentItemQuery } from '../../types/api/graphql-types';

export function useDocumentItem(id: string | null | undefined) {
  const client = useApiClient();

  const { data: documentItemData, loading: documentItemDataLoading } =
    useGetDocumentItemQuery({
      client,
      variables: {
        documentId: id,
      },
      skip: !id,
    });

  const data = useMemo(
    () => documentItemData?.document,
    [documentItemData?.document]
  );

  return {
    data,
    loading: documentItemDataLoading,
  };
}
