import { createContext, useContext } from 'react';

type IntlContextState = {
  loading: boolean;
  language: string;
  languages: { code: string; name: string }[];
  updateLanguage: (language: string) => void;
};

export const IntlContextProps = {
  loading: false,
  language: '',
  languages: [],
  updateLanguage: () => null,
};

export const IntlContext = createContext<IntlContextState>(IntlContextProps);

export const useIntlContext = () => useContext(IntlContext);
