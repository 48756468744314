import './category.scss';

import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCategoryItem } from '../../data/data/categories/use-category-item';
import { DocumentItemData } from '../../data/data/types';
import { useIntlContext } from '../../data/utils/intl-context';
import { Block } from '../../elements/block/block';
import { CustomFormattedMessage } from '../../elements/intl/custom-formatted-message';
import { LoadingMessage } from '../../elements/message/loading-message/loading-messag';
import { PdfViewerModal } from '../../elements/pdf-viewer/pdf-viewer-modal/pdf-viewer-modal';

export function CategoryPage() {
  const { id } = useParams();

  const { language } = useIntlContext();

  const { data: categoryItemData, loading: categoryItemLoading } =
    useCategoryItem(id, language);

  const [pdf, setPdf] = useState<DocumentItemData | false>(false);

  const onHandlePdf = useCallback((value: DocumentItemData) => {
    setPdf(value);
  }, []);

  if (categoryItemLoading) {
    return <LoadingMessage />;
  }

  return categoryItemData ? (
    <>
      <h2>{categoryItemData.name}</h2>

      {categoryItemData.sections.length === 0 ? (
        <p>
          <CustomFormattedMessage id="pages.categories.no-sections" />
        </p>
      ) : (
        <div className="category-section">
          {categoryItemData.sections.map(section => (
            <div key={section.id} className="category-section__section">
              {categoryItemData.sections.length > 1 && <h3>{section.name}</h3>}

              {section.documents.length === 0 ? (
                <p>
                  <CustomFormattedMessage id="pages.categories.no-documents" />
                </p>
              ) : (
                <div className="overview">
                  {section.documents.map(document => (
                    <div key={document.name} className="overview__item">
                      <Block
                        data={document}
                        onClick={e => onHandlePdf(e as DocumentItemData)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {pdf && (
        <PdfViewerModal documentItem={pdf} onClose={() => setPdf(false)} />
      )}
    </>
  ) : (
    <p>
      <CustomFormattedMessage id="pages.categories.not-found" />
    </p>
  );
}
