import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { HomeContact } from '../../compositions/home/home-contact';
import { useCategoryItems } from '../../data/data/categories/use-category-items';
import { CategoryItemData } from '../../data/data/types';
import { useCurrentUser } from '../../data/data/users/use-current-user';
import { useIntlContext } from '../../data/utils/intl-context';
import { Block } from '../../elements/block/block';
import { CustomFormattedMessage } from '../../elements/intl/custom-formatted-message';
import { LoadingMessage } from '../../elements/message/loading-message/loading-messag';

export function HomePage() {
  const navigate = useNavigate();

  const { language } = useIntlContext();

  const { data: userData, loading: userDataLoading } = useCurrentUser();

  const { data: categoriesData, loading: categoriesDataLoading } =
    useCategoryItems(language);

  const onHandleCategory = useCallback(
    (value: CategoryItemData) => {
      navigate(`/categories/${value.id}`);
    },
    [navigate]
  );

  if (userDataLoading || categoriesDataLoading) {
    return <LoadingMessage />;
  }

  return (
    <>
      {userData?.shopName && <h2>{userData?.shopName}</h2>}

      {categoriesData.length === 0 ? (
        <p>
          <CustomFormattedMessage id="pages.home.no-categories" />
        </p>
      ) : (
        <div className="overview">
          {categoriesData.map(category => (
            <div key={category.id} className="overview__item">
              <Block
                data={category}
                onClick={e => onHandleCategory(e as CategoryItemData)}
              />
            </div>
          ))}
        </div>
      )}

      <HomeContact />
    </>
  );
}
