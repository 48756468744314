import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { useCurrentUser } from './data/data/users/use-current-user';
import {
  keycloakClient,
  keycloakClientInitialize,
  KeycloakContext,
} from './data/utils/keycloak-context';

type KeycloakLoaderProps = PropsWithChildren;

export function KeycloakLoader({ ...props }: KeycloakLoaderProps) {
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setAuthenticated(await keycloakClientInitialize);
    })();
  }, []);

  const { data: userData } = useCurrentUser();

  const value = useMemo(
    () => ({
      client: keycloakClient,
      authenticated,
      user: userData ?? null,
    }),
    [authenticated, userData]
  );

  return (
    <KeycloakContext.Provider value={value}>
      {value.authenticated && props.children}
    </KeycloakContext.Provider>
  );
}
