import { first } from 'lodash';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { IntlConfig, IntlProvider } from 'react-intl';

import { useLanguageItems } from './data/data/languages/use-language-items';
import translations from './data/translations/en.json';
import { IntlContext } from './data/utils/intl-context';

const onLoadTranslations = (value: string) =>
  import(`./data/translations/${value}.json`);

type IntlLoaderProps = Omit<IntlConfig, 'locale'> & {
  locale: string | null | undefined;
};

export function IntlLoader({
  locale,
  ...props
}: PropsWithChildren<IntlLoaderProps>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('en');
  const [messages, setMessages] = useState(translations);

  const { data: languageItemsData } = useLanguageItems();

  const languages = useMemo(
    () =>
      languageItemsData.map(option => ({
        code: option.code,
        name: option.name,
      })),
    [languageItemsData]
  );

  useEffect(() => {
    setLanguage(
      first(
        [
          ...[locale?.toLowerCase()],
          ...navigator.language.toLowerCase().split('-').reverse(),
        ].filter(option => languages.find(value => value.code === option))
      ) ??
        first(languages)?.code ??
        'en'
    );
  }, [locale, languages]);

  useEffect(() => {
    setLoading(true);

    onLoadTranslations(language)
      .then(({ default: newTranslations }) => {
        setMessages(prevState => ({ ...prevState, ...newTranslations }));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [language]);

  const updateLanguage = (value: string) => {
    setLanguage(value);

    localStorage.setItem('locale', value);
  };

  const value = useMemo(
    () => ({ loading, languages, language, updateLanguage }),
    [loading, languages, language]
  );

  return (
    <IntlProvider locale={language} messages={messages}>
      <IntlContext.Provider value={value}>
        {props.children}
      </IntlContext.Provider>
    </IntlProvider>
  );
}
