import './loading-message.scss';

import { CustomFormattedMessage } from '../../intl/custom-formatted-message';
import { Spinner } from '../../spinner/spinner';

type LoadingMessageProps = {
  message?: JSX.Element | string;
};

export function LoadingMessage({ message }: LoadingMessageProps) {
  return (
    <div className="loading-message">
      <div className="loading-message__content">
        <Spinner />
        <p>{message ?? <CustomFormattedMessage id="common.loading" />}</p>
      </div>
    </div>
  );
}
