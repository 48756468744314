import { useMemo } from 'react';

import { useApiClient } from '../../GraphQL/api/client';
import { useGetLanguageItemsQuery } from '../../types/api/graphql-types';

export function useLanguageItems() {
  const client = useApiClient();

  const { data: languageItemsData, loading: languageItemsLoading } =
    useGetLanguageItemsQuery({
      client,
    });

  const data = useMemo(
    () => languageItemsData?.languages ?? [],
    [languageItemsData?.languages]
  );

  return {
    data,
    loading: languageItemsLoading,
  };
}
