import type { ReactNode } from 'react';

export const HTMLValues: Record<
  string,
  ((chunks: ReactNode[]) => JSX.Element) | JSX.Element
> = {
  p: chunks => <p>{chunks}</p>,
  strong: chunks => <strong>{chunks}</strong>,
  b: chunks => <strong>{chunks}</strong>,
  em: chunks => <em>{chunks}</em>,
  u: chunks => <u>{chunks}</u>,
  sub: chunks => <sub>{chunks}</sub>,
  sup: chunks => <sup>{chunks}</sup>,
  ol: chunks => <ol>{chunks}</ol>,
  li: chunks => <li>{chunks}</li>,
  ul: chunks => <ul>{chunks}</ul>,
  pre: chunks => <pre>{chunks}</pre>,
  span: chunks => <span>{chunks}</span>,
  linebreak: <br />,
  newLine: <br />,
};
