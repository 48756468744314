import { useMemo } from 'react';

import { useApiClient } from '../../GraphQL/api/client';
import { useGetPageDataQuery } from '../../types/api/graphql-types';

export function usePageData(language: string | null | undefined) {
  const client = useApiClient();

  const { data: pageDataData, loading: pageDataLoading } = useGetPageDataQuery({
    client,
    variables: {
      language: language ?? '',
    },
    skip: !language,
  });

  const data = useMemo(
    () => pageDataData?.page ?? undefined,
    [pageDataData?.page]
  );

  return {
    data,
    loading: pageDataLoading,
  };
}
