import './home-contact.scss';

import { usePageData } from '../../data/data/pages/use-page-data';
import { useIntlContext } from '../../data/utils/intl-context';
import { CustomFormattedMessage } from '../../elements/intl/custom-formatted-message';
import { Section } from '../../elements/section/section';

export function HomeContact() {
  const { language } = useIntlContext();

  const { data: pageData } = usePageData(language);

  if (!pageData) return null;

  return (
    <>
      <h2>
        <CustomFormattedMessage id="pages.home.contact" />
      </h2>
      <Section className="contact">
        {pageData.textBlock0 && (
          <div
            className="richtext"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: pageData.textBlock0,
            }}
          />
        )}

        <div className="section-columns">
          <div className="section-columns__column">
            {pageData.titleBlock1 && <h4>{pageData.titleBlock1}</h4>}

            {pageData.textBlock1 && (
              <div
                className="richtext"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: pageData.textBlock1,
                }}
              />
            )}
          </div>
          <div className="section-columns__column">
            {pageData.titleBlock2 && <h4>{pageData.titleBlock2}</h4>}

            {pageData.textBlock2 && (
              <div
                className="richtext"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: pageData.textBlock2,
                }}
              />
            )}
          </div>
          <div className="section-columns__column">
            {pageData.titleBlock3 && <h4>{pageData.titleBlock3}</h4>}

            {pageData.textBlock3 && (
              <div
                className="richtext"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: pageData.textBlock3,
                }}
              />
            )}

            {pageData.imageBlock3 && (
              <div className="richtext">
                <p className="qr-code">
                  <img src={pageData.imageBlock3} alt="" />
                  <CustomFormattedMessage id="pages.home.qr-code" />
                </p>
              </div>
            )}
          </div>
        </div>
      </Section>
    </>
  );
}
