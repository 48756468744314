import { useMemo } from 'react';

import { useApiClient } from '../../GraphQL/api/client';
import { useGetCurrentUserQuery } from '../../types/api/graphql-types';
import { UserData } from '../types';

export function useCurrentUser() {
  const client = useApiClient();

  const { data: userItemData, loading: userTtemDataLoading } =
    useGetCurrentUserQuery({
      client,
    });

  const data = useMemo(
    () => (userItemData?.user as UserData) ?? undefined,
    [userItemData?.user]
  );

  return {
    data,
    loading: userTtemDataLoading,
  };
}
