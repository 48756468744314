import { KeyboardEventHandler, useMemo } from 'react';

export function makeKeyDownHandler<T>(
  handler?: KeyboardEventHandler<T>
): KeyboardEventHandler<T> | undefined {
  if (!handler) return undefined;

  return event => {
    const { activeElement } = document;

    if (
      activeElement &&
      (activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.tagName === 'SELECT')
    ) {
      return;
    }

    if (event.key === 'Enter' || event.key === ' ') {
      handler(event);

      event.preventDefault();
    }
  };
}

export const useKeyDownHandler = <T,>(handler?: KeyboardEventHandler<T>) =>
  useMemo(() => makeKeyDownHandler(handler), [handler]);
