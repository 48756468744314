import './language-menu.scss';

import { useCallback, useRef, useState } from 'react';

import { useIntlContext } from '../../../data/utils/intl-context';
import { useOnClickOutside } from '../../../data/utils/use-on-click-outside';
import { Button, ButtonIcon, ButtonType } from '../../button/button';

export function LanguageMenu() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    setMenuOpen(false);
  });

  const { language, languages, updateLanguage } = useIntlContext();

  const onHandleLanguage = useCallback(
    (value: string) => {
      updateLanguage(value);

      setMenuOpen(false);
    },
    [updateLanguage]
  );

  if (languages.length <= 1) return null;

  return (
    <div className="language-menu" ref={ref}>
      <Button
        type={ButtonType.ROUND}
        icon={ButtonIcon.CHEVRON}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {languages.find(option => option.code === language)?.name}
      </Button>

      {menuOpen && languages.length > 1 && (
        <ul>
          {languages
            .filter(option => option.code !== language)
            .map(option => (
              <li key={option.code}>
                <button
                  type="button"
                  onClick={() => onHandleLanguage(option.code)}
                >
                  {option.name}
                </button>
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
