import { useMemo } from 'react';

import { useApiClient } from '../../GraphQL/api/client';
import {
  useGetCategoryItemsQuery,
  useGetCategorySectionItemsQuery,
} from '../../types/api/graphql-types';

export function useCategoryItem(
  id: string | null | undefined,
  language: string | null | undefined
) {
  const client = useApiClient();

  const { data: categoryItemsData, loading: categoryItemsLoading } =
    useGetCategoryItemsQuery({
      client,
      variables: {
        language: language ?? '',
      },
      skip: !id || !language,
    });

  const { data: sectionItemsData, loading: sectionItemsLoading } =
    useGetCategorySectionItemsQuery({
      client,
      variables: {
        categoryId: id,
        language: language ?? '',
      },
      skip: !id || !language,
    });

  const data = useMemo(() => {
    const category = categoryItemsData?.categories?.find(
      option => option.id === id
    );

    if (category) {
      return {
        ...category,
        sections: sectionItemsData?.sections ?? [],
      };
    }

    return undefined;
  }, [categoryItemsData?.categories, sectionItemsData?.sections, id]);

  return {
    data,
    loading: categoryItemsLoading || sectionItemsLoading,
  };
}
