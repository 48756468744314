/* eslint-disable no-console */
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { createContext, useContext } from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import {
  keycloakClient,
  keycloakClientInitialize,
} from '../../utils/keycloak-context';

async function getTokens() {
  await keycloakClientInitialize;

  await keycloakClient.updateToken(30);

  return {
    token: keycloakClient.token ?? null,
  };
}

interface Definintion {
  kind: string;
  operation?: string;
}

const url = `${process.env.REACT_APP_BACKEND_API_URL}graphql`;

const wsLink = new WebSocketLink(
  new SubscriptionClient(url.replace('https://', 'wss://'), {
    reconnect: true,
    lazy: true,
  })
);

const httpLink = new HttpLink({
  uri: url,
  credentials: 'same-origin',
});

const authLink = setContext(async (_, { headers }) => {
  const { token } = await getTokens();

  return {
    headers: {
      ...headers,
      Authorization: token,
    },
  };
});

const link = split(
  ({ query }) => {
    const { kind, operation }: Definintion = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(graphQLError => {
          console.error(`[GraphQL error]: ${graphQLError.message}`);
        });
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError.message}`);
      }
    }),
    link,
  ]),
});

const apiContext = createContext(client);

export function useApiClient() {
  return useContext(apiContext);
}
